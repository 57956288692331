import React from 'react';

import Layout from 'components/layout';
import styled, { css } from 'styled-components/macro';
import SEO from 'components/SEO';
import { theme } from 'styled-tools';
import { isAndroid, isIOS, isMobileOnly } from 'react-device-detect';

const PageContent = styled.div`
  padding-top: 4rem;
  background-color: #000000;

  .error-text {
    color: #cc183c;
  }
`;

const MainContainer = styled.div`
  font-family: ${theme('fonts.title')};
  padding: 0.5em 4em;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  justify-content: center;
`;

const SecondaryContainer = styled.div`
  font-family: ${theme('fonts.title')};
  margin: 0 auto;
  padding: 2em 2em;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const AppStoreContainer = styled.div`
  width: 100%;
  margin: 1em;
  margin-bottom: 2em;
  padding: 0 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const ContentHeader = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
`;

const ContentParagraph = styled.div`
  margin-bottom: 15px;

  span {
    font-size: 18px;
    line-height: 28px;
  }

  a {
    color: #ffbc6c;
  }
`;

const HeaderText = styled.span`
  font-size: 34px;
`;

const OrangeHeaderText = styled.span`
  font-size: 34px;
  color: #f68e4a;
`;

const ParagraphText = styled.span`
  font-size: 24px;
  line-height: 28px;

  a {
    color: #ffbc6c;
  }

  ${(props) =>
    props.secondary &&
    css`
      color: black;
    `};
`;

const SmallText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: black;

  a {
    color: #ffbc6c;
  }
`;

const AppStoreLink = styled.img``;
// <img id="asterisk" title="“getting paid” means receiving a free share of stock see full terms for more details." src="https://i.imgur.com/UIGbg9M.png" width="18" height="18">

const responseText = React.createRef();
const headerText = React.createRef();

function fetchReferralCode() {
  var params = {};

  if (location.search) {
    console.log('====in location.search');
    var parts = location.search.substring(1).split('&');

    for (var i = 0; i < parts.length; i++) {
      var nv = parts[i].split('=');
      if (!nv[0]) continue;
      params[nv[0]] = nv[1] || true;
    }
  }

  return { referralCode: params.referral, referredBy: params.referredby };
}

class PasswordResetPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const codes = fetchReferralCode();
    this.setState(codes);
  }

  render() {
    const referralLink = `https://dough.onelink.me/ZNUL/31eec4a4?referral=${this.state.referralCode}&referredBy=${this.state.referredBy}`;
    return (
      <Layout ctaUrl={referralLink}>
        <SEO title='Referrals' />
        <PageContent>
          <MainContainer>
            <ContentHeader>
              <HeaderText>FRIENDS WHO</HeaderText>
            </ContentHeader>
            <ContentHeader>
              <OrangeHeaderText>TRADE TOGETHER</OrangeHeaderText>
            </ContentHeader>
            <ContentHeader>
              <HeaderText title='“getting paid” means receiving a free share of stock see full terms for more details.'>
                GET PAID TOGETHER.*
              </HeaderText>
            </ContentHeader>
            <ContentParagraph>
              <ParagraphText>
                You get free stock when you try dough.
              </ParagraphText>
            </ContentParagraph>
          </MainContainer>
          <SecondaryContainer>
            <ContentParagraph>
              <ParagraphText secondary>
                Download the dough app to receive your free share of stock.
              </ParagraphText>
            </ContentParagraph>
            <AppStoreContainer>
              <a href={referralLink}>
                <img
                  src={
                    'https://images.prismic.io/doughwebsite/bd370183-692e-4c4f-ae56-71647a78884d_app-store-download.png?auto=compress,format'
                  }
                  height='75'
                  width='190'
                />
              </a>
              <a href={referralLink}>
                <img
                  src={
                    'https://images.prismic.io/doughwebsite/72a043e9-26bf-4ec0-b54a-cf1724c43820_google-play-download.png?auto=compress,format'
                  }
                  height='75'
                  width='190'
                />
              </a>
            </AppStoreContainer>
            <SmallText>
              * Beginning on 1-15-2020, $25 min deposit required to withdraw
              funds. See full terms{' '}
              <a href='https://dough.com/referral-terms-and-conditions/'>
                here
              </a>
              .
            </SmallText>
          </SecondaryContainer>
        </PageContent>
      </Layout>
    );
  }
}

export default PasswordResetPage;
